import Cards from "@cloudscape-design/components/cards";
import Link from "@cloudscape-design/components/link";
import StatusIndicator from "@cloudscape-design/components/status-indicator";


interface WorkflowListProps {
    workflows: any
}

const WorkflowList = ({workflows}: WorkflowListProps) => {
    console.log(workflows)
    const targetWorkflows = Object.keys(workflows).map((workflowId) => {
        return {
            id: workflowId,
            ...workflows[workflowId]
        }
    })
    if (targetWorkflows.length === 0) {
        return <>No workflows for this repo</>
    }
    console.log(targetWorkflows)

    return <Cards
        cardDefinition={{
            header: (item: any) => {
                let type = "error";
                let colour = undefined;
                if (item.latest_run?.status === "in_progress") {
                    colour = "yellow"
                    type="loading"
                } else {
                    switch (item.latest_run?.conclusion) {
                        case "success":
                            type = "success"
                            break;
                        case undefined:
                            colour = "grey"
                            type = "stopped"
                            break;

                    }
                }
                //@ts-ignore
                const status = <StatusIndicator type={type} colorOverride={colour} />;
                return (
                    <>
                        {status}
                        <Link href={item.url} external={true} fontSize="heading-m">
                            {item.name}
                        </Link>
                    </>
                )
            },
            sections: [
                {
                    id: "Status",
                    header: "Status",
                    content: (item: any) =>  {
                        const statusMappings = {
                            "success": "Success",
                            "in_progress": "Running",
                            "failure": "Failed"
                        }
                        //@ts-ignore
                        return statusMappings[item.latest_run?.conclusion] || statusMappings[item.latest_run?.status]|| "Never Run"
                    }
                },
                {
                    id: "Target",
                    header: "Target Branch",
                    content: (item: any) =>  {
                        return item.latest_run?.target_branch || "n/a"
                    }
                }

            ]
        }}
        cardsPerRow={[
            { cards: 1 },
            { minWidth: 200, cards: 1 },
            { minWidth: 500, cards: 2 },
            { minWidth: 750, cards: 3 },
            { minWidth: 1000, cards: 4 }

        ]}
        items={targetWorkflows}
        loadingText="Loading resources"
        empty={
            <></>
        }
        header={null}
    />
}

export default WorkflowList;
