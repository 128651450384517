import { useContext, useEffect, useState } from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";

import Container from "@cloudscape-design/components/container";
import Alert from "@cloudscape-design/components/alert"
import Link from "@cloudscape-design/components/link"
import SpaceBetween from "@cloudscape-design/components/space-between"
import Toggle from "@cloudscape-design/components/toggle";
import Input from "@cloudscape-design/components/input";
import FormField from "@cloudscape-design/components/form-field"
import Button from "@cloudscape-design/components/button"
import Header from "@cloudscape-design/components/header"


import ApiHelper from "../Helpers/apiHelper"
import Loading from "./Loading";
import RepoDisplay from "./RepoDisplay";

const RepoList = () => {
    const [apiHelper] = useState(ApiHelper())
    const [isLoading, setIsLoading] = useState(true)
    const [filterText, setFilterText] = useState("")
    const [userRepos, setUserRepos] = useState<any[]>([]);
    const [onlyShowReposWithOpenPr, setOnlyShowReposWithOpenPr] = useState(false);
    const [onlyShowReposWithFailedWorkflows, setOnlyShowReposWithFailedWorkflows] = useState(false);
    const {token} = useContext<IAuthContext>(AuthContext);

    useEffect(() => {
        if (token) {
            apiHelper.makeSignedRequest('repos')
                .then((res) => {
                    setUserRepos(res?.repos.sort((repoa: any, repob: any) => {
                        if (repoa.key.toLowerCase() > repob.key.toLowerCase()) {
                            return 1;
                        }
                        return -1;
                    }));
                    setIsLoading(false)
                })
                .catch((e)=>{
                    if(!e.response) return
                    switch (e.response?.status) {
                        case 404:
                            setIsLoading(false)
                            break;
                        case 403:
                            window.location.reload();
                            break;
                    }
                })
        }
    }, [token]);

    if (isLoading) return <Loading />
    if (userRepos!==undefined && userRepos.length === 0) {
        return (
            <Container>
                <div className="contentPlaceholder">
                    <Alert
                        statusIconAriaLabel="Info"
                        header="No repos selected"
                    >
                        You have not selected any repos to show,<br />
                        Please <Link href={"/#/settings"}>Click Here</Link> to setup your repositories
                    </Alert>
                </div>
            </Container>
        )
    }

    const clearFilters = () => {
        setFilterText("");
        setOnlyShowReposWithOpenPr(false)
        setOnlyShowReposWithFailedWorkflows(false)
    }

    let displayRepos = (userRepos || [])
        .filter((repo)=>{
            if (!onlyShowReposWithOpenPr) return true;
            return repo.prs.openCount > 0;
        })
        .filter((repo)=>{
            if (!onlyShowReposWithFailedWorkflows) return true;
            const failingWorkflowsCount = Object.values(repo.workflows).filter((workflow: any) => {
                return workflow.latest_run?.conclusion === "failure";
            }).length;
            return failingWorkflowsCount > 0;
        })
        .filter((repo)=>{
            if (filterText.length === 0) return true;
            return repo.key.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
        })
    let display = null;

    if (displayRepos.length === 0) {
        display = (
            <Container
                header={
                    <Header
                        variant="h2"
                        description="No matches were found for the selected filters"
                    >
                        No Matches
                    </Header>
                }
            >
                <Button variant={"primary"} onClick={clearFilters}>Clear Filters</Button>
            </Container>
        )
    } else {
        display = displayRepos.map((repo) => {
            return (<RepoDisplay key={repo.key} repo={repo} />)
        });
    }
    return (
            <SpaceBetween size={"l"}>
                <Container>
                    <SpaceBetween size={"s"}>
                        <Toggle
                            onChange={({ detail }) =>
                                setOnlyShowReposWithOpenPr(detail.checked)
                            }
                            checked={onlyShowReposWithOpenPr}
                        >
                            Only Show repos with Open PRs?
                        </Toggle>
                        <Toggle
                            onChange={({ detail }) =>
                                setOnlyShowReposWithFailedWorkflows(detail.checked)
                            }
                            checked={onlyShowReposWithFailedWorkflows}
                        >
                            Only Show repos with Failing workflows?
                        </Toggle>
                        <FormField
                            description="Filter Repos by name"
                        >
                            <Input
                                onChange={({ detail }) => setFilterText(detail.value)}
                                value={filterText}
                                placeholder={"Filter text"}
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
                {display}
            </SpaceBetween>
    )
}

export default RepoList;
