import React, { useContext, useEffect, useState } from 'react';
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {Route, Routes, useNavigate} from 'react-router-dom';
import {isRoleValid} from "./Helpers/role";

import './App.css';

import NoMatch from './Routes/NoMatch';
import AppLayout from '@cloudscape-design/components/app-layout';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import Home from './Routes/Home';
import Settings from './Routes/Settings';
import Loading from './Components/Loading';

let redirectTimoutHandler: NodeJS.Timeout;
function App() {

  const {token, tokenData, logOut} = useContext<IAuthContext>(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(()=> {
    redirectTimoutHandler = setTimeout(() => {
      console.log(token)
      if (!token) {
        navigate("/")
      }
    }, 2000);
    return () => {
      if (redirectTimoutHandler) {
        clearTimeout(redirectTimoutHandler)
      }
    }
  }, [navigate, token])

  useEffect(()=> {
    if (token && tokenData) {
      if (!isRoleValid(tokenData.role)) {
        logOut();
        navigate("/logout/403");
        return;
      }
      setLoading(false);
    }
    if (redirectTimoutHandler && token) {
      clearTimeout(redirectTimoutHandler)
    }
  }, [token, tokenData, logOut, navigate])

  if (loading) return ( <Loading />);
  return (
      <>
        <TopNavigation
            identity={{
              href: "#",
              title: "CloudCall GitHub Status",
            }}
            utilities={[
                {
                    type: "menu-dropdown",
                    iconName: "user-profile-active",
                    ariaLabel: "Settings",
                    title: "Settings",
                    items: [
                        {
                            id: "tepository-settings",
                            text: "Repository Settings",
                            href: "/#/settings"

                        },
                        {
                            id: "sign-out",
                            text: "Sign Out",
                            href: "/#/logout"
                        }
                    ]
                }
            ]}
        />
        <AppLayout
            breadcrumbs={null}
            navigationOpen={false}
            navigationHide={true}
            navigation={null}
            notifications={null }
            toolsHide={true}
            tools={null}
            content={
              <Routes>
                <Route index element={<Home />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<NoMatch />} />
              </Routes>
            }
            splitPanel={null}
        />
    </>
  );
}

export default App;
