import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

interface GitHubAvatarProps {
    user: {
        avatar_url: string
        login: string
    }
    reviewId: number
}

const GitHubAvatar = ({user, reviewId}:GitHubAvatarProps) => {
    return (<div style={{marginRight: "10px", float:"left" }}>
            <img
                data-tooltip-id={`tooltip-${reviewId}-${user.login}`}
                data-tooltip-content={user.login}
                className={"avatarImage"}
                src={user.avatar_url}
                alt={user.login}
            />
            <div >
                <Tooltip id={`tooltip-${reviewId}-${user.login}`} place="top" style={{zIndex: 10000}}/>
            </div>
        </div>)
}

export default GitHubAvatar;
