
import Container from "@cloudscape-design/components/container"
import ContentLayout from "@cloudscape-design/components/content-layout"
import Header from "@cloudscape-design/components/header"

const NoMatch = () => {
    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    Error
                </Header>
            }
        >
            <Container>
                <div className="contentPlaceholder" >
                    That page could not be found
                </div>
            </Container>
        </ContentLayout>
    );
}
export default NoMatch;
