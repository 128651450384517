import {useContext} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import ApiClient from "./ApiClient";

const ApiHelper = () => {
    const {token} = useContext<IAuthContext>(AuthContext);
    return {
        makeSignedRequest: async (path: string, method: string = "get", body?:any) => {
            return ApiClient.makeSignedRequest(token, path, method, body).then((data) => {
                return data;
            });
        }

    }
}

export default ApiHelper;
