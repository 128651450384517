import { useEffect, useState } from "react";

import Button from "@cloudscape-design/components/button"
import Container from "@cloudscape-design/components/container"
import ContentLayout from "@cloudscape-design/components/content-layout"
import Header from "@cloudscape-design/components/header"
import SpaceBetween from "@cloudscape-design/components/space-between"
import FormField from "@cloudscape-design/components/form-field"
import Input from "@cloudscape-design/components/input"
import Multiselect from "@cloudscape-design/components/multiselect";

import ApiHelper from "../Helpers/apiHelper"
import Loading from "../Components/Loading";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";


import {useNavigate} from 'react-router-dom';
const Settings = () => {
    const [apiHelper] = useState(ApiHelper())
    const [isLoading, setIsLoading] = useState(true)
    const [userReposReturned, setUserReposReturned] = useState(false);
    const [userRepos, setUserRepos] = useState<OptionDefinition[]>([]);
    const [userOrgs, setUserOrgs] = useState<OptionDefinition[]>([]);
    const [orgsList, setOrgsList] = useState<any>(undefined);
    const [orgs, setOrgs] = useState({});
    const [reposToShow, setReposToShow] = useState([]);
    const navigate = useNavigate();
    const [githubUsername, setGithubUserName] = useState("");

    useEffect(() => {
        apiHelper.makeSignedRequest('user')
            .then((res)=> {
                setUserReposReturned(true);
                setUserOrgs(res?.orgs.map((org: string) => {
                    return { label: org, value: org}
                }))
                setUserRepos(res?.repos.map((repo: string) => {
                    return { label: repo, value: repo}
                }))

                setGithubUserName(res?.githubUsername)
                setIsLoading(false)
            })
            .catch((e)=>{
                if(!e.response) return
                console.error(e, e.response.status);
                switch (e.response.status) {
                    case 404:
                        setUserReposReturned(true);
                        setUserOrgs([])
                        setUserRepos([])
                        setGithubUserName("")
                        setOrgsList([]);
                        setIsLoading(false)
                        break;
                    case 403:
                        window.location.reload();
                        break;
                }
            })

        apiHelper.makeSignedRequest('orgs')
            .then((res)=> {
                setOrgsList(res);
            })
            .catch(() => {
                setOrgsList({})
            })
    }, []);

    useEffect(() => {
        if (userReposReturned && orgsList!== undefined) {
            setIsLoading(false)
        }
    }, [userRepos, userOrgs, githubUsername, orgsList]);

    useEffect(() => {
        userOrgs.forEach((org)=> {
            const orgName = org.value;
            //@ts-ignore
            if (!orgs[orgName] && orgs[org.value]!=="pending") {
                //@ts-ignore
                orgs[orgName] = "pending";
                setOrgs(orgs);
                apiHelper.makeSignedRequest(`orgs/${orgName}`).then((res) => {
                    const returnedOrg = res?.name.replace("org:", "");
                    //@ts-ignore
                    orgs[returnedOrg] = res?.repos.map((repo) => {
                        return {
                            label: `${returnedOrg} - ${repo.name}`,
                            value: `${returnedOrg}:${repo.name}`
                        }
                    });
                    setOrgs(orgs);
                    updateOrgsToShow();
                })
            }

        })
    }, [userOrgs]);


    const updateOrgsToShow = () => {
        let updatedReposToShow:any[] = [];
        for (const org of userOrgs) {
            //@ts-ignore
            if (orgs[org.value] !== "pending") {
                updatedReposToShow = [
                    ...updatedReposToShow,
                    //@ts-ignore
                    ...orgs[org.value]
                ]
            }
        }
        const orgList = userOrgs.map((org) => org.value);
        setUserRepos(userRepos.filter((repo: any) => {
            const org = repo.value.split(":")[0];
            return orgList.indexOf(org) > -1;
        }));
        //@ts-ignore
        setReposToShow(updatedReposToShow)
    }
    useEffect(updateOrgsToShow, [userOrgs, orgs]);
    const saveSettings = async () =>{
        setIsLoading(true)
        const orgList = userOrgs.map((org) => org.value);
        const repoList = userRepos.map((repo) => repo.value).filter((repo: any) => {
            const org = repo.split(":")[0];
            return orgList.indexOf(org) > -1;
        })
        await apiHelper.makeSignedRequest("user", "post", {
            githubUsername,
            orgs: orgList,
            repos: repoList
        })
        setIsLoading(false)
        navigate("/")
    }

    if (isLoading) return <Loading />


    return (
        <ContentLayout
            header={
                <Header variant="h1">
                    Settings
                </Header>
            }
        >
            <Container>
                <SpaceBetween size={"m"} >
                    <FormField
                        description="GitHub Username"
                    >
                        <Input
                            value={githubUsername}
                            onChange={({ detail }) => setGithubUserName(detail.value)}
                        />
                    </FormField>
                    <FormField
                        description="Oragnisations"
                    >
                        <Multiselect
                            selectedOptions={userOrgs}
                            onChange={({ detail }) =>
                                //@ts-ignore
                                setUserOrgs(detail.selectedOptions)
                            }
                            options={(orgsList || []).map((org: string) => {
                                return {
                                    label: org,
                                    value: org
                                }
                            })}
                            placeholder="Choose orgs"
                        />
                    </FormField>

                    <FormField
                        description="Repositories"
                    >
                        <Multiselect
                            //@ts-ignore
                            selectedOptions={userRepos}
                            onChange={({ detail }) =>
                                //@ts-ignore
                                setUserRepos(detail.selectedOptions)
                            }
                            options={reposToShow}
                            placeholder="Choose repos"
                        />
                    </FormField>
                    <div>
                        <Button
                            variant={"primary"}
                            onClick={saveSettings}
                        >
                            Save
                        </Button>
                    </div>
                </SpaceBetween>
            </Container>
        </ContentLayout>
    );
}
export default Settings;
