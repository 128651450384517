import Cards from "@cloudscape-design/components/cards";
import Link from "@cloudscape-design/components/link";

import GitHubAvatar from "./GitHubAvatar";

import { DateTime } from "luxon";

interface PRListProps {
    prs: any
}

const PRList = ({ prs }: PRListProps) => {
    if (prs.openCount === 0) {
        return <>No open PRs for this repo</>
    }
    return <Cards
        cardDefinition={{
            header: (item: any) => {
                return (<Link href={item.url} external={true} fontSize="heading-m">
                    {item.title}
                </Link>)
            },
            sections: [
                {
                    id: "Created",
                    header: "Created At",
                    content: (item: any) =>  {
                        const parsed = DateTime.fromISO(item.created_at);
                        return parsed.toLocaleString(DateTime.DATETIME_SHORT)
                    }
                },
                {
                    id: "Approved",
                    header: "Approvals",
                    content: (item: any) => {
                        const approvals = item.reviews.APPROVED;
                        if (!approvals || approvals.length === 0 ) {
                            return "No Approvals"
                        }
                        return approvals.map((approval: any) => {
                            //@ts-ignore
                            return <GitHubAvatar user={approval.user} reviewId={approval.id} key={approval.id} />
                        })
                    }
                },
                {
                    id: "Comments",
                    header: "Comments",
                    content: (item: any) => {
                        const comments = item.reviews.COMMENTED;
                        if (!comments || comments.length === 0 ) {
                            return "No Comments"
                        }
                        return comments.map((comment: any) => {
                            //@ts-ignore
                            return <GitHubAvatar user={comment.user} reviewId={comment.id} key={comment.id}/>
                        })
                    }
                },
                {
                    id: "change",
                    header: "Changes Requests",
                    content: (item: any) => {
                        const rejections = item.reviews.CHANGES_REQUESTED;
                        if (!rejections || rejections.length === 0 ) {
                            return "No one has requested changes"
                        }
                        return rejections.map((rejection: any) => {
                            //@ts-ignore
                            return <GitHubAvatar user={rejection.user} reviewId={rejection.id} key={rejection.id} />
                        })
                    }
                }
            ]
        }}
        cardsPerRow={[
            { cards: 1 },
            { minWidth: 200, cards: 1 },
            { minWidth: 500, cards: 2 },
            { minWidth: 750, cards: 3 },
            { minWidth: 1000, cards: 4 }

        ]}
        items={prs.prs}
        loadingText="Loading resources"
        empty={
            <></>
        }
        header={null}
    />
}
export default PRList;
