import Tabs from "@cloudscape-design/components/tabs";
import Badge from "@cloudscape-design/components/badge";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import PRList from "./PRList";
import WorkflowList from "./WorkflowList";

interface RepoDisplayProps {
    repo: any
}

interface LabelProps {
    text: string
    count?: number
}
const Label = ({text, count}: LabelProps) => {

    let targetColour:'blue' | 'grey' | 'green' | 'red' = "grey";
    if (count && count > 0) targetColour = "green"
    if (count && count > 5) targetColour = "red"
    return (<>
        <span style={{marginRight: "10px"}} key={"label"}>{text}</span> <Badge key={"badge"} color={targetColour}>{count}</Badge>
    </>)
}
const WorkflowLabel = ({text, count}: LabelProps) => {

    let targetColour: 'red' = "red";
    if (!count || count === 0) return (<span>{text}</span>);
    return (<>
        <span style={{marginRight: "10px"}} key={"label"}>{text}</span> <Badge key={"badge"} color={targetColour}>{count}</Badge>
    </>)
}

const RepoDisplay = ({repo}:RepoDisplayProps) => {
    const repoNameParts = repo.key.split(":");
    const failingWorkflowsCount = Object.values(repo.workflows).filter((workflow: any) => {
        return workflow.latest_run?.conclusion === "failure";
    }).length;

    return (<Container
        header={
            <Header
                variant="h3"
            >
                {repoNameParts[1]} - {repoNameParts[2]}
            </Header>
        }>
        <Tabs
            tabs={[
                {
                    label: <Label key={repo.key} text={"Open PRs"} count={repo.prs.openCount} />,
                    id: "first",
                    content: <PRList prs={repo.prs} />
                },
                {
                    label:<WorkflowLabel key={repo.key} text={"Workflows"} count={failingWorkflowsCount} />,
                    id: "second",
                    content: <WorkflowList workflows={repo.workflows}/>
                }
            ]}
        />
    </Container>)
}
export default RepoDisplay;
