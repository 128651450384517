import axios from "axios";
import Config from "./config";
const baseUrl = Config.apiBase;

const ApiClient = {
    makeSignedRequest: async (token: string, path: string, method: string = "get", body?:any) => {
        return axios({
            method,
            url: `${baseUrl}/api/${path}`,
            headers: {
                'authorization': token
            },
            data: body
        }).then((res) => {
            return res.data;
        });
    }
}

export default ApiClient
