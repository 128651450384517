import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import { AuthProvider, TRefreshTokenExpiredEvent} from "react-oauth2-code-pkce"
import "@cloudscape-design/global-styles/index.css"
import './index.css';
import App from './App';
import Logout from './Routes/Logout';
import reportWebVitals from './reportWebVitals';
import Config from "./Helpers/config";
const authConfig = {
    ...Config.authConfig,
    onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => window.confirm('Session expired. Refresh page to continue using the site?') && event.login()
}

const router = createHashRouter([
    {
        path: "/logout/:reason",
        element: (<Logout />)
    },{
        path: "/logout",
        element: (<Logout />)
    }, {
        path: "*",
        element: (<AuthProvider authConfig={authConfig}><App /></AuthProvider>)
    }
]);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
