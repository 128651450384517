import { ContentLayout, Header} from "@cloudscape-design/components";

import RepoList from "../Components/RepoList";


const Home = () => {

    return (
        <ContentLayout
            header={
                <Header variant="h1">
                </Header>
            }
        >
            <RepoList />
        </ContentLayout>
    );
}
export default Home;
