import React from 'react';
import {useParams} from "react-router-dom";

function Logout() {
    const params = useParams();
    let message;
    let redirectUrl = `${process.env.REACT_APP_LOGIN_URL}/Account/Logout?PostLogoutRedirectUri=${process.env.REACT_APP_REDIRECT_URL}`;

    try {
        window.localStorage.removeItem("ROCP_token");
        window.localStorage.removeItem("ROCP_idToken");
        window.localStorage.removeItem("PKCE_code_verifier");
        window.localStorage.removeItem("ROCP_tokenExpire");
        window.localStorage.removeItem("ROCP_loginMethod");
        window.localStorage.removeItem("ROCP_loginInProgress");
    }
    catch (e) {
        console.error(e)
    }

    switch (params.reason) {
        case "403":
            message = (<div>
                You do not have the correct role to use this portal, please contact your administrator for access <br />
                Click <a href={redirectUrl}>here</a> to logout
            </div>)
            break;
        default:
            window.location.href=redirectUrl;
            message = (
                <div>
                    If you are not re-directed please click <a href={redirectUrl}>here</a> to logout
                </div>
            );
            break;

    }

    return message
}

export default Logout;

